import { ReactElement, Suspense, lazy } from 'react'
import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const BusinessClientsListView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "BusinessClientsListView" */ /* webpackPrefetch: true */ './views/Index')
)
const NewBusinessClientView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "NewBusinessClientView" */ /* webpackPrefetch: true */ './views/New')
)
const EditBusinessClientView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "EditBusinessClientView" */ /* webpackPrefetch: true */ './views/Edit')
)
const BusinessClientUsersView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "BusinessClientUsersView" */ /* webpackPrefetch: true */ './views/BusinessClientUsers')
)
const NewBusinessClientUserView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(
      /* webpackChunkName: "NewBusinessClientUserView" */ /* webpackPrefetch: true */ './views/NewBusinessClientUser'
    )
)
const EditBusinessClientUserView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(
      /* webpackChunkName: "EditBusinessClientUserView" */ /* webpackPrefetch: true */ './views/EditBusinessClientUser'
    )
)

export const BusinessClientsRoutes = [
  {
    path: '/business-clients',
    children: [
      {
        index: true,
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <BusinessClientsListView />
            </Suspense>
          </AppContent>
        )
      },
      {
        path: ':businessClientId',
        children: [
          {
            index: true,
            element: (
              <AppContent>
                <Suspense fallback={<PrefetchLoader />}>
                  <EditBusinessClientView />
                </Suspense>
              </AppContent>
            )
          },
          {
            path: 'users',
            children: [
              {
                index: true,
                element: (
                  <AppContent>
                    <Suspense fallback={<PrefetchLoader />}>
                      <BusinessClientUsersView />
                    </Suspense>
                  </AppContent>
                )
              },
              {
                path: 'new',
                element: (
                  <AppContent>
                    <Suspense fallback={<PrefetchLoader />}>
                      <NewBusinessClientUserView />
                    </Suspense>
                  </AppContent>
                )
              },
              {
                path: ':businessClientUserId',
                element: (
                  <AppContent>
                    <Suspense fallback={<PrefetchLoader />}>
                      <EditBusinessClientUserView />
                    </Suspense>
                  </AppContent>
                )
              }
            ]
          }
        ]
      },
      {
        path: 'new',
        element: (
          <AppContent>
            <Suspense fallback={<PrefetchLoader />}>
              <NewBusinessClientView />
            </Suspense>
          </AppContent>
        )
      }
    ]
  }
]
