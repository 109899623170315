import { ReactElement, Suspense, lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { PrefetchLoader } from '@10xLabs/react-web/components/PrefetchLoader'
import { AppContent } from 'components/v2/Content'

const BusinessClientUsersView = lazy(
  (): Promise<{ default: () => ReactElement }> =>
    import(/* webpackChunkName: "BusinessClientUsersView" */ /* webpackPrefetch: true */ './views/Index')
)

export const BusinessClientUsersRoutes = {
  path: '/business-client-users',
  children: [
    {
      index: true,
      element: (
        <AppContent>
          <Suspense fallback={<PrefetchLoader />}>
            <BusinessClientUsersView />
          </Suspense>
        </AppContent>
      )
    },
    {
      path: '*',
      element: <Navigate to="/business-client-users" replace />
    }
  ]
}
